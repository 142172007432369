// Lib imports
import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import store from "../store";

// Routes
import paths from "./paths";

Vue.use(Router);

// Create a new router
const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    if (!store.getters.isLoggedIn) {
        next({ name: 'Denied' })
    } else {
      if (to.meta.roles) {
        if (store.getters.getRoles.includes(to.meta.roles[0])) {
          next()
        }
        else {
          next({ name: 'Denied' })
        }
      }
      else {
        next()
      }
    }
  } else {
    next()
  }
})

Vue.use(Meta);

export default router;
